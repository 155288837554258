import { render, staticRenderFns } from "./mine-address-page.vue?vue&type=template&id=5bb0b643&scoped=true"
import script from "./mine-address-page.vue?vue&type=script&lang=js"
export * from "./mine-address-page.vue?vue&type=script&lang=js"
import style0 from "./mine-address-page.vue?vue&type=style&index=0&id=5bb0b643&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bb0b643",
  null
  
)

export default component.exports